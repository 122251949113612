import { SvgIcon, SvgIconProps } from './SvgIcon';

export const AddToDashboardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3H9V9H3V3ZM11 3H17V9H11V3ZM3 11H9V17H3V11ZM13.25 11H14.75V13.25H17V14.75H14.75V17H13.25V14.75H11V13.25H13.25V11ZM12.5 4.5V7.5H15.5V4.5H12.5ZM4.5 4.5V7.5H7.5V4.5H4.5ZM4.5 12.5V15.5H7.5V12.5H4.5Z"
      />
    </SvgIcon>
  );
};
